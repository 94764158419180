import {
  Box,
  Chip,
  Divider,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import lodash from "lodash";
import {
  edit,
  book,
  home,
  settings,
  Signout,
  moreRound,
  arrowBottom,
  arrowTop,
  mockTest,
  orderManagement
} from "../../../../assets/svgs";
import { ObjectValues, inputProp } from "../../../../pages/login";
import CustomPopover from "../../../../Components/CustomPopover";
import CustomContainedButton from "../../../../Components/CustomContainedButton";
import { CustomListItem } from "../../../../Components/CustemListItem";
import globalColors from "../../../../globalColors";
import { iconText, subHeading } from "../../../../Components/CustomTexts";
import {
  displayColumn,
  rowHorizontalCenter,
  rowVerticalCenter,
} from "../../../../GlobalStyles";
import CustomIconButton from "../../../../Components/CustomIconButton";
import { Fragment } from "react";
import moment from "moment";
import MessagePopper from "../../../../Components/MessagePopper";
import { Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails } from "@material-ui/lab";
import { TextField, TextFieldProps,Select } from '@material-ui/core';
import { Language } from "@mui/icons-material";
import { contentFeedback } from "../../../../assets/svgs/contentFeedback";
export const serverDetails = "https://test.netpractice.in/api"; //"https://ng.netpractice.in/api";
export const historyArray = [
  {
    label: "Update",
    content: (row: ObjectValues) => (
      <Box sx={{ ...displayColumn, gap: "10px", minWidth: "22%" }}>
        {subHeading({ text: "Updated on" })}
        <Box sx={{ color: "#838383" }}>
          {subHeading({
            text: moment(row.modified_at ?? row.created_at).format(
              "DD-MMM-YYYY, h:mm A"
            ),
            size: "0.85rem",
          })}{" "}
        </Box>
      </Box>
    ),
    sx: { flex: 1, textAlign: "start", minWidth: "22%" },
  },
  {
    content: (row: ObjectValues) => (
      <Box sx={{ ...displayColumn, gap: "10px" }}>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "6px 30px 6px 30px",
            background:
              globalColors.colorCombinations[row.new_status]?.bg ?? "",
            color: globalColors.colorCombinations[row.new_status]?.color ?? "",
            textTransform: "capitalize",
            ...rowHorizontalCenter,
            maxWidth: "fit-content",
            fontSize: "0.75rem",
            lineHeight: "normal",
          }}
        >
          {row.new_status}
        </Box>
        {row.feedback && (
          <Box>
            {subHeading({
              text: row.feedback ? "Internal Remark : " : "Reason : ",
              sx: { color: "#838383" },
              size: "0.87rem",
            })}
            {subHeading({
              text: row.feedback,
              sx: { color: "#121212" },
              size: "0.87rem",
            })}
          </Box>
        )}
      </Box>
    ),
    label: "Status",
    sx: { flex: 8, minWidth: "56%" },
  },
  {
    label: "Action By",
    content: (row: ObjectValues) => (
      <Box sx={{ ...displayColumn, gap: "10px", minWidth: "22%" }}>
        {subHeading({
          text: row.modified_by_user_name ?? row.created_by_user_name,
          sx: { color: "#121212" },
        })}
      </Box>
    ),
    sx: { flex: 1, minWidth: "22%" },
  },
];

interface Option {
  value: string;
  label: string;
}
export interface statusType {
  label: string;
  value: number;
  actionLabel: string | ((status: string) => string);
  badgeCount: number;
  disabled?: boolean;
}
export const languages = [
  "assamese",
  "bengali",
  "english",
  "gujarati",
  "hindi",
  "kannada",
  "malayalam",
  "marachi",
  "odia",
  "punjabi",
  "tamil",
  "telugu",
  "urdu",
];
export const mockTestLanguages: Option[] = [
  {value: "English", label: "English"},
  {value: "Hindi", label: "Hindi"}
]
export const mockTestLevel = [
  "easy",
  "medium",
  "hard"
]
export const goalDateTypes = ["tentative", "announced"];
export const allStatus: statusType[] = [
  {
    label: "all",
    value: 0,
    actionLabel: (status: string) => `${status}`,
    badgeCount: 0,
  },
  { label: "draft", value: 1, actionLabel: "Move to Draft", badgeCount :  0},
  { label: "ready", value: 2, actionLabel: "Ready",badgeCount : 0 },
  { label: "approved", value: 3, actionLabel: "Approve",badgeCount : 0 },
  { label: "published", value: 4, actionLabel: "Publish",badgeCount : 0 },
  { label: "archived", value: 5, actionLabel: "Archive",badgeCount : 0 },
  { label: "rejected", value: 6, actionLabel: "Reject",badgeCount : 0 },
  { label: "unpublished", value: 7, actionLabel: "Unpublish",badgeCount : 0 },
];

export const allStatusInMock: statusType[] = [
  { label: "draft", value: 1, actionLabel: "Move to Draft", badgeCount :  0},
  { label: "ready", value: 2, actionLabel: "Ready",badgeCount : 0 },
  { label: "approved", value: 3, actionLabel: "Approve",badgeCount : 0 },
  { label: "published", value: 4, actionLabel: "Publish",badgeCount : 0 },
  { label: "archived", value: 5, actionLabel: "Archive",badgeCount : 0 },
  { label: "rejected", value: 6, actionLabel: "Reject",badgeCount : 0 },
  { label: "unpublished", value: 7, actionLabel: "Unpublish",badgeCount : 0 },
];

export const allStatusInClusterQuestion: statusType[] = [
  { label: "draft", value: 1, actionLabel: "Move to Draft", badgeCount :  0},
  { label: "ready", value: 2, actionLabel: "Ready",badgeCount : 0 },
  { label: "approved", value: 3, actionLabel: "Approve",badgeCount : 0 },
  { label: "published", value: 4, actionLabel: "Publish",badgeCount : 0 },
  { label: "archived", value: 5, actionLabel: "Archive",badgeCount : 0 },
  { label: "rejected", value: 6, actionLabel: "Reject",badgeCount : 0 },
  { label: "unpublished", value: 7, actionLabel: "Unpublish",badgeCount : 0 },
];

export const allStatusInMockTestModule: statusType[] = [
  { label: "approved", value: 1, actionLabel: "Approve",badgeCount : 0 },
  { label: "published", value: 2, actionLabel: "Publish",badgeCount : 0 },
];

export const allStatusInMockTestModule2: statusType[] = [
  { label: "draft", value: 1, actionLabel: "Move to Draft", badgeCount :  0},
  { label: "ready", value: 2, actionLabel: "Ready",badgeCount : 0 },
  { label: "approved", value: 3, actionLabel: "Approve",badgeCount : 0 },
  { label: "published", value: 4, actionLabel: "Publish",badgeCount : 0 },
  { label: "released", value: 5, actionLabel: "Release",badgeCount : 0 },
  { label: "archived", value: 6, actionLabel: "Archive",badgeCount : 0 },
  { label: "rejected", value: 7, actionLabel: "Reject",badgeCount : 0 },
  { label: "unpublished", value: 8, actionLabel: "Unpublish",badgeCount : 0 },
];

export const allModules = [
  "exams",
  "goals",
  "sections",
  "unit",
  "topics",
  "subtopics",
  "clusters",
  "questions",
];

export const allModulesInMockTest = [
  "exams",
  "goals",
  "mock Test Series",
  "mock Tests",
  "section",
  "question",
];
export const allPermissions = [
  "exam",
  "goal",
  "syllabustreenode",
  "syllabustreenode",
  "syllabustreenode",
  "syllabustreenode",
  "syllabustreecluster",
  "question",
];
export const allPermissionsMockTest = [
  "exam",
  "goal",
  "mocktestseries",
  "mocktest",
  "mocktestsection",
  "question",
];

const actionContent: any = (ss: any, { buttonarr, actionarr }: any) => {
  return (
    <Box sx={{ height: "100%", ...rowVerticalCenter }}>
      <Stack
        spacing="3px"
        justifyContent={"center"}
        sx={{ marginRight: "6px", flex: 8 }}
      >
        {buttonarr(ss.data)?.map((button: any, i: number) => {
          return i === 1 ? (
            <CustomIconButton
              key={button.content}
              {...button}
              sx={{
                ...button.sx,
                fontSize: "0.8rem !important",
                minWidth: "fit-content",
              }}
              variant={"outlined"}
            />
          ) : (
            <CustomContainedButton
              key={button.content}
              otherProps={{
                variant: "contained",
                ...button,
                key: button.content,
                sx: {
                  ...button.sx,
                  fontSize: "0.8rem !important",
                  minWidth: "fit-content",
                },
              }}
              {...button}
            />
          );
        })}
      </Stack>
      {actionarr(ss.data)?.length > 0 ? (
        <CustomPopover
          mt="5px !important"
          icon={moreRound}
          content={
            <Stack spacing={"5px"} minWidth={"75px"}>
              {actionarr(ss.data)?.map(
                (
                  {
                    label,
                    icon,
                    onclick,
                  }: { label: string; icon?: any; onclick: any },
                  i: number
                ) => {
                  return (
                    <Fragment key={label}>
                      <CustomListItem
                        onClick={() => {
                          onclick(ss.data);
                        }}
                        key={label}
                        alignItems="flex-start"
                        sx={{
                          display: "block",
                          color: globalColors.primary.black,
                          borderRadius: "7px",
                          transition: "background-color 0.3s ease", // Optional: Add a transition for smoother effect
                          "&:hover": {
                            background: globalColors.primary.lightGrey, // Change the background color on hover
                            color: globalColors.primary.black,
                            borderRadius: "7px", // Change the text color on hover
                          },
                          mb: "5px",
                          p: "0",
                        }}
                      >
                        <ListItemButton
                          sx={{
                            maxHeight: "40px",
                            padding: "5px 8px !important",
                            borderRadius: "0 !important",
                            background: "transparent !important",
                          }}
                        >
                          {icon}
                          <ListItemText
                            sx={{ ...{ m: "0", p: "0" } }}
                            primary={label ?? ``}
                          />
                        </ListItemButton>
                      </CustomListItem>
                      {i + 1 !== actionarr(ss.data)?.length && <Divider />}
                    </Fragment>
                  );
                }
              )}
            </Stack>
          }
        />
      ) : (
        ""
      )}
    </Box>
  );
};
export const nameColumn: any = (content: any, name: string, width?: string) => {
  const newObj = {
    field: "name",
    // colspan: ({data }:any) => {
    //   return data.name==="SSC CGL"? 4:1
    // },
    width: width ?? allWidth.name,
    headerName: name ?? "Exam Name",
    // defaultFlex: 1,
    // defaultWidth: 200,
    textAlign: "start",
    textVerticalAlign: "middle",

    headerAlign: "start",
    renderCell: ({ data, value }: any) => {
      return (
        <div
          style={{
            display: "inline-block",
          }}
          onClick={() => {
            content.onPreview(data);
          }}
        >
          {iconText({
            text: value ?? "",
            classname: "linkStyle-always",
            size: "0.75rem",
          })}
        </div>
      );
    },
  };
  return newObj;
};
const allWidth = {
  name: 200,
  path: 120,
  primary_language: 180,
  status: 140,
  action: (actionWidth: any) => actionWidth(),
  goal_type: 180,
  registered_users: 160,
  logo: 80,
};
export const logoColumn: any = (content: any, name?: string) => {
  const newObj = {
    field: "logo",
    sortable: true,
    headerName: "Logo",
    width: allWidth.logo,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};

export const descriptionColumn: any = (content: any, width?: number) => {
  const newObj = {
    field: "description",
    sortable: true,
    headerName: "Description",
    defaultFlex: 2,
    width: width,
    // ? width < 200 ? 200 : width : 200,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
    renderCell: ({ value }: any) => {
      return <MessagePopper text={value} id={"Description"} />;
    },
  };
  return newObj;
};
export const internalRemark: any = (content: any, width?: number) => {
  const newObj = {
    field: "internal_remark",
    // minWidth: 200,
    // maxWidth: 200,
    width: width, //? width < 200 ? 200 : width : 200,
    sortable: true,
    defaultFlex: 2,
    headerName: "Internal Remark",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
    renderCell: ({ value }: any) => {
      return <MessagePopper text={value} id={"Internal"} />;
    },
  };
  return newObj;
};
export const pathOrder: any = (content: any, width?: string) => {
  const newObj = {
    field: "path_order",
    width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "number",
    headerName: "Path Order",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};

export const pathOrderForCluster: any = (content: any, width?: string) => {
  const newObj = {
    field: "path_order",
    minWidth: 100,
          maxWidth: 300,
    //width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "number",
    headerName: "Path Order",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};

export const convertFirstUpper = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};
export const statusContent: any = (ss: any, s: any, width: number) => {
  let hide = false;
  return (
    <Stack sx={{ width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          borderRadius: "8px",
          padding: width && width < 100 ? "6px 10px" : "6px 30px 6px 30px",
          background: globalColors.colorCombinations[ss.value]?.bg ?? "",
          color: globalColors.colorCombinations[ss.value]?.color ?? "",
          textTransform: "capitalize",
          ...rowHorizontalCenter,
          // minWidth: "fit-content",
          maxWidth: "100%",
          fontSize: "0.75rem",
          lineHeight: "normal",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          overflow: "hidden",
          width: "fit-content",
        }}
      >
        {ss.value}
      </Box>
      {
        <CustomIconButton
          key="arrow"
          sx={{
            whiteSpace: "nowrap",
            width: "fit-content",
            padding: "3px 5px",
            marginTop: "5px",
            maxWidth: "150px",
            fontWeight: 400,
            color: globalColors.primary.purple,
            fontSize: "0.75rem",
            minHeight: "22.97px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            hide = !hide;

            ss.toggleRowExpand();
            if (!ss.rowExpanded) {
              s.fetchHistorys(ss.data);
            }
            // ss.toggleRowExpand()
          }}
          noHover={true}
          icon={
            ss.rowExpanded
              ? width && width < 100
                ? contents.hidehistorySm
                : contents.hidehistory
              : width && width < 100
              ? contents.viewhistorySm
              : contents.viewhistory
          }
          content={ss.rowExpanded ? arrowTop : arrowBottom}
          // <Stack
          //   direction="row"
          //   sx={{ ...rowVerticalCenter, color: globalColors.primary.purple }}
          //   spacing={"4px"}
          // >
          //   <Typography
          //     fontStyle={"normal"}
          //     fontWeight={400}
          //     fontSize={"0.9rem"}
          //     color={"currentColor"}
          //     textTransform={"capitalize"}
          //   >

          //   </Typography>
          // </Stack>
          // }
        />
      }
    </Stack>
  );
};
export const actionColumn: any = (content: any, width?: any) => {
  return {
    field: "Action",
    headerName: "Action",
    // type: "text",
    // defaultFlex: 1,
    width: width ?? allWidth.action(content.actionWidth),
    sortable: false,
    // filterEditorProps: {
    //   customFilterFunction: false,
    // },
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",

    renderCell: (ss: any) => {
      return actionContent(ss, content);
    },
    // headerProps: { className:"noMenu" }
  };
};

export const actionColumnForCluster: any = (content: any, width?: any) => {
  return {
    field: "Action",
    headerName: "Action",
    // type: "text",
    // defaultFlex: 1,
    //width: width ?? allWidth.action(content.actionWidth),
    minWidth: 100,
    maxWidth: 300,
    sortable: false,
    // filterEditorProps: {
    //   customFilterFunction: false,
    // },
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",

    renderCell: (ss: any) => {
      return actionContent(ss, content);
    },
    // headerProps: { className:"noMenu" }
  };
};

export const descriptionInput = () => {
  return {
    label: "Description",
    placeholder: "Enter Your Description",
    value: "",
    error: false,
    helperText: " c",
    endAdornment: "",
    multiline: 5,
    fieldType: "text",
    api: "description",
  };
};
export const goalDateContent = (row: ObjectValues, content: any) => {
  return (
    <Stack spacing="3px">
      {(row?.data.tentative_publish_date || row?.data.publish_date) && (
        <Box
          sx={{
            borderRadius: "8px",
            padding: "4px 11px 4px 11px",
            background: globalColors.primary.chipGreyColor,
            color: globalColors.primary.black,
            textTransform: "capitalize",
            ...rowHorizontalCenter,

            maxWidth: "fit-content",
          }}
        >
          {" "}
          {subHeading({
            text: row.data.publish_date
              ? "Announced"
              : row.data.tentative_publish_date
              ? "Tentative"
              : "",
            size: "0.75rem",
            sx: { textAlign: "center", lineHeight: "normal" },
          })}
        </Box>
      )}

      {subHeading({
        text: row.data.publish_date ?? row.data.tentative_publish_date ?? "",
        size: "0.75rem",
        sx: { color: "#121212", paddingLeft: "2px" },
      })}
    </Stack>
  );
};
//  const logoInput = () => {
//   return {
//     label: "Logo",
//     placeholder: "Choose Logo image",
//     value: "",
//     error: false,
//     helperText: " c",
//     endAdornment: "",
//     fieldType: "image",
//     api: "logo_url",
//     not_required: true,
//   }
// }
const ImageUpload = () => {
  return {
    label: "Genererate Image URL",
    placeholder: "Choose image",
    value: "",
    error: false,
    helperText: " c",
    endAdornment: "",
    fieldType: "image",
    api: "logo_url",
    not_required: true,
  };
};
export const weightageInput = () => {
  return {
    label: "Weightage",
    // not_required: true,
    placeholder: "Enter Weightage number",
    // only_number: true,
    value: "",
    endAdornment: <Box sx={{ padding: "0 20px", fontSize: "1rem" }}>%</Box>,
    error: false,
    helperText: " c",
    fieldType: "text",
    api: "weightage",
    dataType: "number",
  };
};
export const weightageColumn: any = (
  content: any,
  name: string,
  width?: string
) => {
  const newObj = {
    field: "weightage",
    // colspan: ({data }:any) => {
    //   return data.name==="SSC CGL"? 4:1
    // },
    width: width ?? allWidth.name,
    headerName: "Weightage",
    // defaultFlex: 1,
    // defaultWidth: 200,
    textAlign: "start",
    textVerticalAlign: "middle",

    headerAlign: "start",
    renderCell: ({ data, value }: any) => {
      return `${value} %`;
    },
  };
  return newObj;
};
export const pathOrderInput = () => {
  return {
    label: "Path Order",
    // not_required: true,
    placeholder: "Enter Path Order Number",
    only_number: true,
    value: "",
    error: false,
    helperText: " c",
    endAdornment: "",
    fieldType: "text",
    api: "path_order",
    dataType: "number",
  };
};
export const internalRemarkInput = () => {
  return {
    label: "Internal Remark",
    placeholder: "Enter Your Remark",
    value: "",
    error: false,
    helperText: " c",
    endAdornment: "",
    multiline: 5,
    fieldType: "text",
    api: "internal_remark",
    not_required: true,
  };
};

export const languagesInMockTest: any = (content: any, width?: string) => {
  const newObj = {
    field: "language",
    width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "text",
    headerName: "Languages",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};
export const releaseDate: any = (content: any, width?: string) => {
  const newObj = {
    field: "release_datetime",
    width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "datetime",
    headerName: "Release Date & Time",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};
export const author: any = (content: any, width?: string) => {
  const newObj = {
    field: "author",
    width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "text",
    headerName: "Author",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};

export const approver: any = (content: any, width?: string) => {
  const newObj = {
    field: "approver",
    width: width ?? 120,
    sortable: true,
    // filterEditor: NumberFilter,
    type: "text",
    headerName: "Approver",
    // defaultFlex: 1,
    textAlign: "start",
    textVerticalAlign: "middle",
    headerAlign: "start",
  };
  return newObj;
};

 

export const ModuleDetails = {
  [allModules[0]]: {
    apiCall: false,
    isTab: true,
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `${row.name} exam has been successfully updated. Once the corresponding Executive approves the changes in exam, it will be available.`
        : `${res.name} exam has been successfully created. Once the corresponding Executive approves the exam, it will be visible.`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const minColumnWidth = 100;
const eqWidth = lodash.max([lodash.divide(Number(content.tableWidth), 6), minColumnWidth]);
console.log(eqWidth);
      return [
        nameColumn(content, null, eqWidth),
        descriptionColumn(content, eqWidth),

        {
          field: "primary_language",
          width: eqWidth,
          sortable: true,
          enableColumnFilterContextMenu: true,
          headerName: "Primary Language",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            return (
              <Box
                sx={{
                  textTransform: "capitalize",
                  height: "100%",
                  ...rowVerticalCenter,
                }}
              >
                {value ?? ""}
              </Box>
            );
          },
        },
        internalRemark(content, eqWidth),
        {
          field: "status",
          width: eqWidth,
          sortable: true,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, eqWidth);
          },
        },
        actionColumn(content, eqWidth),
      ];
    },
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
      { name: "primary_language", operator: "eq", type: "select", value: null },
    ],
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView?: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Exam Name",
          placeholder: "Enter Exam Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Primary Language",
          placeholder: "Select Primary Language of Exam",
          value: undefined,
          list: languages.map((it: string) => {
            return {
              label: convertFirstUpper(it),
              id: it,
              value: convertFirstUpper(it),
            };
          }),
          defaultValue: {
            value: row?.primary_language
              ? convertFirstUpper(row?.primary_language)
              : "",
            id: row?.primary_language,
          },
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          listLabel: "label",
          api: "primary_language",
          // freeSolo:true
        },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModules[1]]: {
    apiCall: false,
    isTab: true,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
      { name: "path_order", operator: "eq", type: "number", value: null },
      { name: "registered_users", operator: "eq", type: "number", value: null },
      { name: "goal_type", operator: "eq", type: "select", value: null },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `${res.name} has been successfully updated. Once the corresponding Executive approves the changes in goal, it will be visible.`
        : `${res.name} has been successfully created. Once the corresponding Executive approves the goal, it will be visible.`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
              goal_type: item.publish_date
                ? "announced"
                : item.tentative_publish_date
                ? "tentative"
                : "",
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(Number(content.tableWidth), 8);
      const longWidth = eqWidth + 20;
      const finalWidth = eqWidth - lodash.divide(40, 6);
      const statusWidth =
        finalWidth < allWidth.status ? allWidth.status : finalWidth;
      const goalDateWidth =
        finalWidth < allWidth.goal_type ? allWidth.goal_type : finalWidth;
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal =
        statusWidth === finalWidth &&
        goalDateWidth === finalWidth &&
        actionWidth === finalWidth
          ? finalWidth
          : finalWidth -
            lodash.divide(
              goalDateWidth -
                finalWidth +
                (statusWidth - finalWidth) +
                (actionWidth - finalWidth),
              3
            );
      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Goal Name", allFinal),
        {
          field: "goal_type",
          width: goalDateWidth,
          sortable: true,
          headerName: "Goal Date",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: (ss: any) => {
            return goalDateContent(ss, content);
          },
        },
        descriptionColumn(content, longWidth),
        internalRemark(content, longWidth),
        {
          field: "registered_users",
          width: allFinal,
          // maxWidth: 200,

          // filterEditor: NumberFilter,
          type: "number",
          sortable: true,
          headerName: "Registered Users",
          // defaultFlex: 1,
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
        },
        {
          field: "status",
          sortable: true,
          width: statusWidth,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, statusWidth);
          },
        },
        actionColumn(content, actionWidth),
      ];
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Goal Name",
          placeholder: "Enter Goal Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Goal Date",
          placeholder: "Enter Goal Name",
          defaultValue: {
            value: row?.publish_date ?? row?.tentative_publish_date ?? "",
          },
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "date",
          api: "date",
        },
        {
          label: "Parent Exam",
          // disabled:true,
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.exam)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.exam : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "exam",
        },
        // pathOrderInput(),
        {
          label: "Date Type",
          placeholder: "Select Date type",
          value: undefined,
          defaultValue: {
            value: row?.publish_date
              ? "Announced"
              : row?.tentative_publish_date
              ? "Tentative"
              : "",
            id: row?.publish_date
              ? "announced"
              : row?.tentative_publish_date
              ? "tentative"
              : "",
          },

          list: goalDateTypes.map((it: string) => {
            return {
              label: convertFirstUpper(it),
              id: it,
              value: convertFirstUpper(it),
            };
          }),
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          listLabel: "label",
          api: "date_type",
          // freeSolo:true
        },
        { fieldType: "extra" },
        { fieldType: "extra" },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },

    formatPayload: (data: ObjectValues) => {
      const newObj = {
        name: data.name,
        internal_remark: data.internal_remark,
        status: data.status,
        // path_order: 11,
        // "notification_date": "2024-01-1",
        // "tentative_notification_date": data.date_type==="tentative"?data.date:null,

        description: data.description,
        // "minimum_depth": 2147483647,
        // "maximum_depth": 2147483647,
        // "quiz_available_from_level": 2147483647,
        is_active: true,
        exam: data.exam,
        tag: [],
        [data.date_type === "announced"
          ? "publish_date"
          : "tentative_publish_date"]: data.date,
      };
      return newObj;
    },
  },
  [allModules[2]]: {
    apiCall: false,
    isTab: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],

    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Section has been successfully created`
        : `Section has been updated created`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(
        Number(content.tableWidth),
        content.noAction() ? 5 : 6
      );
      const longWidth = eqWidth + 20;
      const finalWidth =
        eqWidth - lodash.divide(50, content.noAction() ? 5 : 6);
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal = content.noAction()
        ? finalWidth
        : actionWidth === finalWidth
        ? finalWidth
        : finalWidth - lodash.divide(actionWidth - finalWidth, 3);

      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Section Name", allFinal),
        descriptionColumn(content, longWidth),
        weightageColumn(content, allFinal),
        // logoColumn(content),
        internalRemark(content, longWidth),
        !content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
        // name: data.name,
        internal_remark: data.internal_remark,
        // path_order: 11,
        description: data.description,
        is_active: true,
        goal: data.goal,
        parent: null,
        logo_url: JSON.stringify(data.logo_url),
        weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Section Name",
          placeholder: "Enter Section Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Parent Goal",
          // disabled:true,
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.goal)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.goal : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "goal",
        },
        // pathOrderInput(),
        weightageInput(),

        // logoInput(),
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModules[3]]: {
    apiCall: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    isTab: false,
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Unit has been successfully created`
        : `Unit has been updated created`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(
        Number(content.tableWidth),
        content.noAction() ? 5 : 6
      );
      const longWidth = eqWidth + 20;
      const finalWidth =
        eqWidth - lodash.divide(50, content.noAction() ? 5 : 6);
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal = content.noAction()
        ? finalWidth
        : actionWidth === finalWidth
        ? finalWidth
        : finalWidth - lodash.divide(actionWidth - finalWidth, 3);

      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Unit Name", allFinal),
        descriptionColumn(content, longWidth),
        weightageColumn(content, allFinal),
        // logoColumn(content),
        internalRemark(content, longWidth),

        !content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
        // name: data.name,
        internal_remark: data.internal_remark,
        // path_order: 11,
        description: data.description,
        is_active: true,
        goal: data.goal,
        parent: data.parent,
        logo_url: JSON.stringify(data.logo_url),
        weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Unit Name",
          placeholder: "Enter Unit Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        // pathOrderInput(),

        {
          label: "Parent Section",
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.parent)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.parent : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "parent",
        },

        weightageInput(),

        // logoInput(),
        //  { fieldType: "extra" }, { fieldType: "extra" },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModules[4]]: {
    apiCall: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    isTab: false,
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Topic has been successfully created`
        : `Topic has been updated created`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(
        Number(content.tableWidth),
        content.noAction() ? 5 : 6
      );
      const longWidth = eqWidth + 20;
      const finalWidth =
        eqWidth - lodash.divide(50, content.noAction() ? 5 : 6);
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal = content.noAction()
        ? finalWidth
        : actionWidth === finalWidth
        ? finalWidth
        : finalWidth - lodash.divide(actionWidth - finalWidth, 3);

      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Topic Name", allFinal),
        descriptionColumn(content, longWidth),
        weightageColumn(content, allFinal),
        // logoColumn(content),
        internalRemark(content, longWidth),

        !content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
        // name: data.name,
        internal_remark: data.internal_remark,
        // path_order: 11,
        description: data.description,
        is_active: true,
        goal: data.goal,
        parent: data.parent,
        logo_url: JSON.stringify(data.logo_url),
        weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Topic Name",
          placeholder: "Enter Topic Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Parent Unit",
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.parent)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.parent : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "parent",
        },
        // pathOrderInput(),

        weightageInput(),

        // logoInput()
        // ,
        // { fieldType: "extra" }, { fieldType: "extra" },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModules[5]]: {
    apiCall: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Subtopic has been successfully created`
        : `Subtopic has been updated created`;
    },
    isTab: false,
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(
        Number(content.tableWidth),
        content.noAction() ? 5 : 6
      );
      const longWidth = eqWidth + 20;
      const finalWidth =
        eqWidth - lodash.divide(50, content.noAction() ? 5 : 6);
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal = content.noAction()
        ? finalWidth
        : actionWidth === finalWidth
        ? finalWidth
        : finalWidth - lodash.divide(actionWidth - finalWidth, 3);

      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Subtopic Name", allFinal),
        descriptionColumn(content, longWidth),
        weightageColumn(content, allFinal),
        // logoColumn(content),
        internalRemark(content, longWidth),

        !content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
        // name: data.name,
        internal_remark: data.internal_remark,
        // path_order: 11,
        description: data.description,
        is_active: true,
        goal: data.goal,
        parent: data.parent,
        logo_url: JSON.stringify(data.logo_url),
        weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Subtopic Name",
          placeholder: "Enter Subtopic Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Parent Topic",
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.parent)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.parent : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "parent",
        },
        // pathOrderInput(),

        weightageInput(),
        // logoInput()

        //  { fieldType: "extra" }
        // , { fieldType: "extra" },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModules[6]]: {
    apiCall: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `${
            row.cluster?.name ?? res.cluster?.name ?? "Cluster"
          } has been successfully updated. Once the corresponding Executive approves the changes, it will be available.`
        : `Cluster has been successfully created. Once the corresponding Executive approves the cluster, it will be available.`;
    },
    isTab: true,
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
              name: item.cluster?.name ?? "",
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      // const margin = lodash.divide(Number(80), 9);
      // const all = lodash.divide(Number(content.tableWidth), 9) - margin;

      // const path_width = all > 105 ? 105 : all;
      // const eqWidth = lodash.sum([
      //   all,
      //   lodash.divide(lodash.subtract(all, path_width), 8),
      // ]);
      const fixedEqWidth = 150; // Example fixed width in pixels
      return [
        pathOrderForCluster(content, fixedEqWidth),
        {
          field: "cluster_details",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Cluster Details",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ data, value }: any) => {
            return (
              <Stack spacing="12px" sx={{ wordBreak: "break-all" }}>
                <div
                  style={{
                    display: "inline-block",
                  }}
                  onClick={() => {
                    content.onPreview(data);
                  }}
                >
                  {iconText({
                    text: data.name ?? "",
                    classname: "linkStyle-always",
                    size: "0.75rem",
                  })}
                </div>
                <Box
                  sx={{
                    borderRadius: "4px",

                    padding: "6px 5px",
                    background: globalColors.primary.chipGreyColor,
                    color: globalColors.primary.black,
                    textTransform: "capitalize",
                    ...rowHorizontalCenter,

                    maxWidth: "fit-content",
                  }}
                >
                  {" "}
                  {subHeading({
                    text: `${data.cluster?.users_started}/${data.cluster?.users_completed}`,
                    size: "0.75rem",
                    sx: { textAlign: "center", lineHeight: "normal" },
                  })}
                </Box>
                {subHeading({
                  text: "Started/Completed",
                  size: "0.75rem",
                  sx: {
                    textAlign: "center",
                    lineHeight: "normal",
                    whiteSpace: "normal",
                  },
                })}
              </Stack>
            );
          },
        },
        {
          field: "cluster_type",
          width: fixedEqWidth,
          sortable: true,
          minWidth: 100,
          maxWidth: 300,
          headerName: "Cluster Type",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ data }: any) => {
            const type =
              data.cluster?.whitelisted_questions?.length > 0
                ? "whitelist"
                : "free";
            return (
              <Stack spacing="5px">
                <Box
                  sx={{
                    borderRadius: "8px",
                    padding: "6px 20px 6px 20px",
                    background: globalColors.colorCombinations[type]?.bg ?? "",
                    color: globalColors.colorCombinations[type]?.color ?? "",
                    textTransform: "capitalize",
                    ...rowHorizontalCenter,
                    maxWidth: "fit-content",
                    fontSize: "0.75rem",
                    lineHeight: "normal",
                  }}
                >
                  {type}
                </Box>
              </Stack>
            );
          },
        },
        {
          field: "example_question",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Example Question",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            return (
              <MessagePopper
                text={`Select the INCORRECTLY spelt word. Lorem ipsum is simply dummy text Select the INCORRECTLY spelt word. Lorem ipsum is simply dummy text Select the INCORRECTLY spelt word. Lorem ipsum is simp`}
                id={"example_question"}
              />
            );
          },
        },
        {
          field: "que_details",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Que. Details",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ data, value }: any) => {
            return (
              <Stack spacing="12px" sx={{ wordBreak: "break-all" }}>
                {/* <Box
          sx={{
            borderRadius: "4px",
            padding: "6px 5px",
            background: globalColors.primary.chipGreyColor,
            color: globalColors.primary.black,
            textTransform: "capitalize",
            ...rowHorizontalCenter,

            maxWidth: "fit-content",
          }}
        >
          {" "}
          {subHeading({
            text:"Total Question",
            size: "0.75rem",
            sx: { textAlign: "center", lineHeight: "normal" },
          })}
        </Box> */}
                <Box
                  sx={{
                    borderRadius: "4px",

                    padding: "6px 5px",
                    background: globalColors.primary.chipGreyColor,
                    color: globalColors.primary.black,
                    textTransform: "capitalize",
                    ...rowHorizontalCenter,

                    maxWidth: "fit-content",
                  }}
                >
                  {" "}
                  {subHeading({
                    text: `${data.cluster?.whitelisted_questions}/${data.cluster?.total_questions}`,
                    size: "0.75rem",
                    sx: { textAlign: "center", lineHeight: "normal" },
                  })}
                </Box>{" "}
                {subHeading({
                  text: "Whitelist/Total",
                  size: "0.75rem",
                  sx: {
                    textAlign: "center",
                    lineHeight: "normal",
                    whiteSpace: "normal",
                  },
                })}
                {/* {subHeading({text:data.cluster?.whitelisted_questions })} */}
              </Stack>
            );
          },
        },
        {
          field: "indices",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Indices",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ data }: any) => {
            return (
              <Stack spacing="5px" sx={{ color: globalColors.primary.black }}>
                {subHeading({ text: "Exposure Index:", size: "0.75rem" })}
                {subHeading({
                  text: data.cluster?.exposure_index,
                  size: "0.75rem",
                })}
                {subHeading({ text: "Accuracy Index:", size: "0.75rem" })}
                {subHeading({
                  text: data.cluster?.accuracy_index,
                  size: "0.75rem",
                })}
              </Stack>
            );
          },
        },
        {
          field: "duration",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Duration",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ data, value }: any) => {
            return (
              <Stack spacing="5px" sx={{ color: globalColors.primary.black }}>
                {subHeading({ text: "Minimum Time:", size: "0.75rem" })}
                {subHeading({ text: `0 min`, size: "0.75rem" })}
                {subHeading({ text: "Maximum Time:", size: "0.75rem" })}
                {subHeading({ text: `0 min`, size: "0.75rem" })}
              </Stack>
            );
          },
        },
        {
          field: "status",
          width: fixedEqWidth,
          minWidth: 100,
          maxWidth: 300,
          sortable: true,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(
              { ...ss, value: ss.data.cluster?.status },
              content,
              fixedEqWidth
            );
          },
        },
        actionColumnForCluster(content, fixedEqWidth),
      ];
    },
    formatPayload: (data: ObjectValues, step: number) => {
      const newObj =
        step && step === 1
          ? data
          : {
              cluster: {
                name: data.name,
                exposure_index: data.exposure_index,
                accuracy_index: data.accuracy_index,
                retention_index: data.retention_index,
                lod_index: data.lod_index,
              },
              is_selection_set: false,
              syllabus_tree_node: data.parent,
            };
      return newObj;
    },
    inputArray: (content: any) => {
      return [];
    },
    inputSteps: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          id: 0,
          formatPayload: (data: ObjectValues) => {
            const newObj = {
              cluster: {
                name: data.name,
                exposure_index: data.exposure_index,
                accuracy_index: data.accuracy_index,
                retention_index: data.retention_index,
                lod_index: data.lod_index,
              },
              is_selection_set: false,
              syllabus_tree_node: data.parent,
            };
            return newObj;
          },
          label: "Add Cluster",
          inputFormArray: [
            {
              not_send_id: true,
              label: "Cluster Name",
              placeholder: "Enter Cluster Name",
              value: "",
              error: false,
              helperText: " c",
              endAdornment: "",
              onUpdate: async (val: ObjectValues) => {
                if (content.fetchSingleClusterQuestions && val.id) {
                  const res: any = await content.fetchSingleClusterQuestions(
                    val.id
                  );

                  return {
                    "Cluster Name": res.name,
                    "Cluster NameId": res.id,
                    "Exposure Index": res.exposure_index,
                    "Accuracy Index": res.accuracy_index,
                    "Retention Index": res.retention_index,
                    "Lod Index": res.lod_index,
                    cluster_res: res,
                  };
                } else {
                  return {
                    "Cluster Name": val.name,
                    "Cluster NameId": val.id,
                    // "Exposure Index":val.exposure_index,
                    // "Accuracy Index":val.accuracy_index,
                    // "Retention Index":val.retention_index,
                    // "Lod Index":val.lod_index
                  };
                }
              },
              fieldType: row ? "text" : "autocomplete",
              list: content.all_clusters ?? [],
              listLabel: "name",
              api: "name",
              freeSolo: true,
              defaultValue: {
                value: row?.cluster?.name ?? "",
              },
            },
            {
              label: "Parent Subtopic",
              pinned: true,
              defaultValue: {
                value:
                  row && listView
                    ? listView.list?.find(
                        (it) => it.id === row?.syllabus_tree_node
                      )?.name
                    : listView?.selected_row?.name ?? "",
                id: row
                  ? row?.syllabus_tree_node
                  : listView?.selected_row?.id ?? "",
              },
              value: "",
              list: listView?.list ?? [],

              listLabel: listView?.selected_row_field ?? "name",
              error: false,
              helperText: " c",
              endAdornment: "",
              fieldType: "select",
              disabled: true,
              api: "parent",
            },
            {
              label: "Exposure Index",
              // not_required: true,
              placeholder: "Enter Exposure Index Number",

              value: "",
              error: false,
              helperText: " c",
              endAdornment: "",
              fieldType: "text",
              api: "exposure_index",
              dataType: "number",
              defaultValue: {
                value: row?.cluster?.exposure_index ?? "",
              },
            },
            {
              label: "Accuracy Index",
              // not_required: true,
              placeholder: "Enter Accuracy Index Number",
              defaultValue: {
                value: row?.cluster?.accuracy_index ?? "",
              },
              value: "",
              error: false,
              helperText: " c",
              endAdornment: "",
              fieldType: "text",
              api: "accuracy_index",
              dataType: "number",
            },
            {
              label: "Retention Index",
              // not_required: true,
              placeholder: "Enter Retention Index Number",

              value: "",
              error: false,
              helperText: " c",
              endAdornment: "",
              fieldType: "text",
              api: "retention_index",
              defaultValue: {
                value: row?.cluster?.retention_index ?? "",
              },
              dataType: "number",
            },
            {
              label: "Lod Index",
              // not_required: true,
              placeholder: "Enter Lod Index Number",

              value: "",
              error: false,
              helperText: " c",
              endAdornment: "",
              fieldType: "text",
              api: "lod_index",
              dataType: "number",
              defaultValue: {
                value: row?.cluster?.lod_index ?? "",
              },
            },
          ],
        },
        {
          id: 1,
          label: "Add Question to Cluster",
          inputFormArray: [],
          formatPayload: (data: ObjectValues) => {
            return data;
          },
        },
      ] as {
        label: string;
        inputFormArray: any;
        id: number;
        formatPayload(payload: ObjectValues): void;
      }[];
    },
  },
  [allModules[7]]: {
    apiCall: false,
    // arrange_path: t
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Question has been successfully created. Once the corresponding Executive approves the Question, it will be available.`
        : `Question has been successfully updated. Once the corresponding Executive approves the changes in Question, it will be available.`;
    },
    isTab: true,
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
              name: item?.translation?.text ?? "",
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const margin = lodash.divide(Number(60), 7);
      const all = lodash.divide(Number(content.tableWidth), 7) - margin;

      const eqWidth = all;
      return [
        {
          field: "question",
          width: eqWidth,
          sortable: true,
          headerName: "Question",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.translation?.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return (
              <MessagePopper
                fetchSingleQuestionDetails={content.fetchSingleQuestionDetails}
                text={text ?? ""}
                type="ck"
                id={"question"}
                question={data}
              />
            );
          },
        },
        {
          field: "answer",
          width: eqWidth,
          sortable: true,
          headerName: "Answer",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.answer?.translation.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return <MessagePopper text={text ?? ""} type="ck" id={"Answer"} />;
          },
        },
        {
          field: "explanation",
          width: eqWidth,
          sortable: true,
          headerName: "Explanation",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.explanation?.translation.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return (
              <MessagePopper text={text ?? ""} type="ck" id={"Explanation"} />
            );
          },
        },
        {
          field: "default_language",
          width: eqWidth,
          sortable: true,
          headerName: "Default Language",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            return (
              <Box
                sx={{
                  textTransform: "capitalize",
                  height: "100%",
                  ...rowVerticalCenter,
                }}
              >
                {value ?? ""}
              </Box>
            );
          },
        },
        {
          field: "tag",
          width: eqWidth,
          sortable: true,
          headerName: "Question Tag",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            const tags =
              content.tags_list.filter((it: any) => value.includes(it.id)) ??
              [];
            return (
              <MessagePopper
                content={
                  <Box>
                    {tags.map(({ name }: any) => (
                      <Chip
                        label={name}
                        sx={{
                          margin: "5px",
                          background: "#E3F4E7",
                          maxWidth: "calc(100% - 10px)",
                          "& .MuiChip-label": {
                            fontSize: "0.69srem",
                            lineHeight: "1rem",
                          },
                        }}
                      ></Chip>
                    ))}
                  </Box>
                }
                id={"question"}
              />
            );
          },
        },
        {
          field: "status",
          width: eqWidth,
          sortable: true,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, eqWidth);
          },
        },
        actionColumn(content, eqWidth),
      ];
    },
    inputSteps: () => {
      return [];
    },
    formatPayload: (data: ObjectValues, que_id?: any) => {
      const newObj: any = {
        cluster: data.parent,
        text: data.question,
        tag: data.tag?.length > 0 ? data.tag.map((it: any) => it.id) : [],
        options: [
          {
            text: data.option1,
            is_correct: data.correct_answer === "Option A" ? true : false,
            media_urls: "",
          },
          {
            text: data.option2,
            is_correct: data.correct_answer === "Option B" ? true : false,

            media_urls: "",
          },
          {
            text: data.option3,
            is_correct: data.correct_answer === "Option C" ? true : false,
            media_urls: "",
          },
          {
            text: data.option4,
            is_correct: data.correct_answer === "Option D" ? true : false,
            media_urls: "",
          },
        ],
        explanation: {
          media_urls: "",
          text: data.explanation,
        },
        question: que_id ? que_id : null,
        language: data.primary_language,
        // default_language:data.primary_language,
        is_default_language: data.is_default_language ?? que_id ? false : true,
        media_urls: "",
      };
      if (newObj.is_default_language) {
        newObj.default_language = newObj.language;
      }

      return newObj;
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        ImageUpload(),
        {
          label: "Default Language",
          placeholder: "Select Default Language",
          value: undefined,
          error: false,
          helperText: " c",
          endAdornment: "",
          not_required: true,
          fieldType: "checkbox",
          api: "is_default_language",
        },

        {
          label: "Question Language",
          placeholder: "Select Question Language",
          value: undefined,
          list: languages.map((it: string) => {
            return {
              label: convertFirstUpper(it),
              id: it,
              value: convertFirstUpper(it),
            };
          }),
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          listLabel: "label",
          api: "primary_language",
          // freeSolo:true
        },
        {
          label: "Parent Cluster",
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.cluster.id === row?.cluster)
                    ?.cluster?.name
                : listView?.selected_row?.cluster?.name ??
                  listView?.selected_row?.name ??
                  "",
            id: row
              ? row?.cluster
              : listView?.selected_row?.cluster?.id ??
                listView?.selected_row?.id ??
                "",
          },
          value: "",
          list:
            listView?.list && listView?.list.length > 0
              ? listView?.list.map((item: ObjectValues) => {
                  return {
                    ...item,
                    name: item?.cluster?.name ?? item?.name ?? "",
                    id: item?.cluster?.id ?? item?.id ?? "",
                  };
                })
              : [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "parent",
        },
        {
          label: "Question",
          // grid:{xs:12,sx:{width:"100%"}},
          fullWidth: true,
          // not_required: true,
          placeholder: "Enter Question",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "cktextEditor",
          api: "question",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option A",
          // not_required: true,
          placeholder: "Enter Option A",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "cktextEditor",
          dataType: "option",
          api: "option1",
        },
        {
          label: "Option B",
          // not_required: true,
          placeholder: "Enter Option B",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option2",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option C",
          // not_required: true,
          placeholder: "Enter Option C",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option3",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option D",
          // not_required: true,
          placeholder: "Enter Option D",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option4",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Explanation",
          // grid:{xs:12,sx:{width:"100%"}},
          fullWidth: true,
          // not_required: true,
          placeholder: "Enter Explanation",

          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "cktextEditor",
          api: "explanation",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Question Tags",
          // pinned: true,
          // defaultValue: {
          //   // value:
          //   //   row && listView
          //   //     ? listView.list?.find((it) => it.id === row?.parent)?.name
          //   //     : listView?.selected_row?.name ?? "",
          //   // id: row ? row?.parent : listView?.selected_row?.id ?? "",
          // },
          // value: [],
          list: content.tags_list ?? [],
          multiple: true,
          listLabel: "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          not_required: true,
          freeSolo: {
            dialog: true,
          },
          // disabled: true,
          api: "tag",
        },
      ] as inputProp[];
    },
  },
};

export const ModuleDetailsInMockTest = {
  [allModulesInMockTest[0]]: {
    apiCall: false,
    isTab: true,
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `${row.name} exam has been successfully updated. Once the corresponding Executive approves the changes in exam, it will be available.`
        : `${res.name} exam has been successfully created. Once the corresponding Executive approves the exam, it will be visible.`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      // const eqWidth = lodash.divide(Number(content.tableWidth), 6);
      // const igWidth = eqWidth + 20;
      // const finalWidth = eqWidth - 40;
      const eqWidth = lodash.divide(1400, 5); // Replace with fixed value for testing
      return [
        nameColumn(content, null, eqWidth),
        descriptionColumn(content, eqWidth),

        {
          field: "primary_language",
          width: eqWidth,
          sortable: true,
          enableColumnFilterContextMenu: true,
          headerName: "Primary Language",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            return (
              <Box
                sx={{
                  textTransform: "capitalize",
                  height: "100%",
                  ...rowVerticalCenter,
                }}
              >
                {value ?? ""}
              </Box>
            );
          },
        },
        internalRemark(content, eqWidth),
        {
          field: "status",
          width: eqWidth,
          sortable: true,
          headerName: "Status & Action",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return "Exam Status and Settings can be configured only from Exams & Topical Mastery Module";
          },
        },
        //actionColumn(content, finalWidth),
      ];
    },
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
      { name: "primary_language", operator: "eq", type: "select", value: null },
    ],
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView?: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Exam Name",
          placeholder: "Enter Exam Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Primary Language",
          placeholder: "Select Primary Language of Exam",
          value: undefined,
          list: languages.map((it: string) => {
            return {
              label: convertFirstUpper(it),
              id: it,
              value: convertFirstUpper(it),
            };
          }),
          defaultValue: {
            value: row?.primary_language
              ? convertFirstUpper(row?.primary_language)
              : "",
            id: row?.primary_language,
          },
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          listLabel: "label",
          api: "primary_language",
          // freeSolo:true
        },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModulesInMockTest[1]]: {
    apiCall: false,
    isTab: true,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
      { name: "path_order", operator: "eq", type: "number", value: null },
      { name: "registered_users", operator: "eq", type: "number", value: null },
      { name: "goal_type", operator: "eq", type: "select", value: null },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `${res.name} has been successfully updated. Once the corresponding Executive approves the changes in goal, it will be visible.`
        : `${res.name} has been successfully created. Once the corresponding Executive approves the goal, it will be visible.`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
              goal_type: item.publish_date
                ? "announced"
                : item.tentative_publish_date
                ? "tentative"
                : "",
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(Number(content.tableWidth), 8);
      const longWidth = eqWidth + 20;
      const finalWidth = eqWidth - lodash.divide(40, 6);
      const statusWidth =
        finalWidth < allWidth.status ? allWidth.status : finalWidth;
      const goalDateWidth =
        finalWidth < allWidth.goal_type ? allWidth.goal_type : finalWidth;
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal =
        statusWidth === finalWidth &&
        goalDateWidth === finalWidth &&
        actionWidth === finalWidth
          ? finalWidth
          : finalWidth -
            lodash.divide(
              goalDateWidth -
                finalWidth +
                (statusWidth - finalWidth) +
                (actionWidth - finalWidth),
              3
            );
      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Goal Name", allFinal),
        {
          field: "goal_type",
          width: goalDateWidth,
          sortable: true,
          headerName: "Goal Date",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: (ss: any) => {
            return goalDateContent(ss, content);
          },
        },
        descriptionColumn(content, longWidth),
        internalRemark(content, longWidth),
        {
          field: "registered_users",
          width: allFinal,
          // maxWidth: 200,

          // filterEditor: NumberFilter,
          type: "number",
          sortable: true,
          headerName: "Registered Users",
          // defaultFlex: 1,
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
        },
        {
          field: "status",
          sortable: true,
          width: 300,
          headerName: "Status & Action",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return "Exam Status and Settings can be configured only from Exams & Topical Mastery Module";
          },
        },
        //actionColumn(content, actionWidth),
      ];
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Goal Name",
          placeholder: "Enter Goal Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Goal Date",
          placeholder: "Enter Goal Name",
          defaultValue: {
            value: row?.publish_date ?? row?.tentative_publish_date ?? "",
          },
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "date",
          api: "date",
        },
        {
          label: "Parent Exam",
          // disabled:true,
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.exam)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.exam : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "exam",
        },
        // pathOrderInput(),
        {
          label: "Date Type",
          placeholder: "Select Date type",
          value: undefined,
          defaultValue: {
            value: row?.publish_date
              ? "Announced"
              : row?.tentative_publish_date
              ? "Tentative"
              : "",
            id: row?.publish_date
              ? "announced"
              : row?.tentative_publish_date
              ? "tentative"
              : "",
          },

          list: goalDateTypes.map((it: string) => {
            return {
              label: convertFirstUpper(it),
              id: it,
              value: convertFirstUpper(it),
            };
          }),
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "autocomplete",
          listLabel: "label",
          api: "date_type",
          // freeSolo:true
        },
        { fieldType: "extra" },
        { fieldType: "extra" },
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },

    formatPayload: (data: ObjectValues) => {
      const newObj = {
        name: data.name,
        internal_remark: data.internal_remark,
        status: data.status,
        // path_order: 11,
        // "notification_date": "2024-01-1",
        // "tentative_notification_date": data.date_type==="tentative"?data.date:null,

        description: data.description,
        // "minimum_depth": 2147483647,
        // "maximum_depth": 2147483647,
        // "quiz_available_from_level": 2147483647,
        is_active: true,
        exam: data.exam,
        tag: [],
        [data.date_type === "announced"
          ? "publish_date"
          : "tentative_publish_date"]: data.date,
      };
      return newObj;
    },
  },
  [allModulesInMockTest[2]]: {
    apiCall: false,
    isTab: true,
    arrange_path: false,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Mock Test Series has been updated`
        : `Mock Test Series has been successfully created`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const margin = lodash.divide(Number(60), 7);
      const all = lodash.divide(Number(content.tableWidth), 7) - margin;

      const eqWidth = all;

      return [
        nameColumn(content, "Name", eqWidth),
        releaseDate(content, eqWidth),
        descriptionColumn(content, eqWidth),
        languagesInMockTest(content, eqWidth),
        internalRemark(content, eqWidth),
        {
          field: "status",
          sortable: true,
          width: eqWidth,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, eqWidth);
          },
        },
        !content.noAction() && actionColumn(content, eqWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
         name: data.name,
        internal_remark: data.internal_remark,
        release_datetime: data.release_datetime,
        description: data.description,
        language: data.primary_language?.toString(),
        //is_active: true,
        goal: data.goal,
        //parent: null,
        //logo_url: JSON.stringify(data.logo_url),
        //weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Name",
          placeholder: "Enter Name",
          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Release Date & Time",
          placeholder: "Enter Release Date",
          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "dateTime",
          api: "release_datetime",
        },
        {
          label: "Language",
          placeholder: "Select Mock Test Series Language",
          pinned: true,
          value: ["English", "Hindi"],
          list: mockTestLanguages,
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "multiselect",
          //fieldType: "autocomplete",
          listLabel: "label",
          api: "primary_language",
        },
        descriptionInput(),
        internalRemarkInput(),
       
      ] as inputProp[];
    },
  },
  [allModulesInMockTest[3]]: {
    apiCall: false,
    isTab: true,
    arrange_path: false,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `Mock Test has been updated`
        : `Mock Test has been successfully created`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const margin = lodash.divide(Number(80), 9);
      const all = lodash.divide(Number(content.tableWidth), 9) - margin;

      const path_width = all > 105 ? 105 : all;
      const eqWidth = lodash.sum([
        all,
        lodash.divide(lodash.subtract(all, path_width), 8),
      ]);

      return [
        nameColumn(content, "Name", eqWidth),
        descriptionColumn(content, eqWidth),
        languagesInMockTest(content, eqWidth),
        internalRemark(content, eqWidth),
        releaseDate(content, eqWidth),
        author(content, eqWidth),
        approver(content, eqWidth),
        {
          field: "status",
          sortable: true,
          width: eqWidth,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, eqWidth);
          },
        },
        actionColumn(content, eqWidth),
        // logoColumn(content),
        //!content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
         name: data.name,
        description: data.description,
        mock_test_series: data.mock_test_series,
        marks_for_correct: data.marks_for_correct,
        marks_for_incorrect: data.marks_for_incorrect,
        estimated_time: data.estimated_time,
        //author: "fc79efa8-d9b6-4418-94af-f61a0ae68e9b",
        //approver: "fc79efa8-d9b6-4418-94af-f61a0ae68e9b",
        release_datetime: data.release_datetime,
        internal_remark: data.internal_remark,
        difficulty_level: data.difficulty_level,
        language: data.primary_language?.toString(),
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
     inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Mock Test Name",
          placeholder: "Enter Mock Test Name",
          value: "",
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "text",
          api: "name",
          fieldSetName: "Mock Test",
        },
        {
          label: "Language",
          placeholder: "Select Mock Test Language",
          pinned: true,
          value: undefined,
          list: mockTestLanguages,
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "multiselect",
          //fieldType: "autocomplete",
          listLabel: "label",
          api: "language",
          fieldSetName: "Mock Test",
        },
        {
          label: "Mock Test Level Of Difficulty",
          placeholder: "Select Mock Test Level Of Difficulty",
          pinned: true,
          value: undefined,
          list: mockTestLevel.map((it: string) => {
            return {
              label: (it),
              id: it,
              value: (it),
            };
          }),
          error: false,
          helperText: " c",
          endAdornment: "",
          fieldType: "select",
          listLabel: "label",
          api: "difficulty_level",
          fieldSetName: "Mock Test",
        },
        {
          label: "Marks For Correct Answers",
          // not_required: true,
          placeholder: "Enter Marks For Correct Answers",
          // only_number: true,
          value: "",
          error: false,
          helperText: "",
          fieldType: "text",
          api: "marks_for_correct",
          dataType: "number",
          fieldSetName: "Mock Test",
          allowNegative: true,
          validate: true,
          validateExp: /^-?(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/
        },
        {
          label: "Marks For Incorrect Answers",
          // not_required: true,
          placeholder: "Enter Marks For Incorrect Answers",
          // only_number: true,
          value: "",
          error: false,
          helperText: "",
          fieldType: "text",
          api: "marks_for_incorrect",
          dataType: "number",
          fieldSetName: "Mock Test",
          allowNegative: true,
          validate: true,
          validateExp: /^-?(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/
        },
        {
          label: "Release Date & Time",
          placeholder: "Enter Release Date & Time",
          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "dateTime",
          api: "release_datetime",
          fieldSetName: "Mock Test",
        },
        {
          label: "Estimated Time",
          // not_required: true,
          placeholder: "Enter Estimated Time",
          // only_number: true,
          value: "",
          error: false,
          helperText: "",
          fieldType: "text",
          api: "estimated_time",
          dataType: "number",
          fieldSetName: "Mock Test",
        },
        {
    label: "Description",
    placeholder: "Enter Your Description",
    value: "",
    error: false,
    helperText: " c",
    endAdornment: "",
    multiline: 5,
    fieldType: "text",
    api: "description",
    fieldSetName: "Mock Test"
  },

        
        
        {fieldSetName: "Mock Test"},
        internalRemarkInput(),
       
        

      ] as inputProp[];
    },
    isServerSide: false
  },
  [allModulesInMockTest[4]]: {
    apiCall: false,
    isTab: false,
    arrange_path: true,
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Section has been successfully created`
        : `Section has been updated`;
    },
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const eqWidth = lodash.divide(
        Number(content.tableWidth),
        content.noAction() ? 5 : 6
      );
      const longWidth = eqWidth + 20;
      const finalWidth =
        eqWidth - lodash.divide(50, content.noAction() ? 5 : 6);
      const actionWidth =
        finalWidth < allWidth.action(content.actionWidth)
          ? allWidth.action(content.actionWidth)
          : finalWidth;
      const allFinal = content.noAction()
        ? finalWidth
        : actionWidth === finalWidth
        ? finalWidth
        : finalWidth - lodash.divide(actionWidth - finalWidth, 3);

      return [
        pathOrder(content, allFinal),
        nameColumn(content, "Section Name", allFinal),
        descriptionColumn(content, longWidth),
        weightageColumn(content, allFinal),
        // logoColumn(content),
        internalRemark(content, longWidth),
        !content.noAction() && actionColumn(content, actionWidth),
      ];
    },
    formatPayload: (data: ObjectValues, s: any, edit: any) => {
      const newObj: any = {
        // name: data.name,
        internal_remark: data.internal_remark,
        // path_order: 11,
        description: data.description,
        is_active: true,
        goal: data.goal,
        parent: null,
        logo_url: JSON.stringify(data.logo_url),
        weightage: data.weightage,
      };
      if (edit) {
        if (edit.name !== data.name) {
          newObj.name = data.name;
        }
      } else {
        newObj.name = data.name;
      }
      return newObj;
    },
    inputSteps: () => {
      return [];
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        {
          label: "Section Name",
          placeholder: "Enter Section Name",
          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "text",
          api: "name",
        },
        {
          label: "Parent Goal",
          // disabled:true,
          pinned: true,
          defaultValue: {
            value:
              row && listView
                ? listView.list?.find((it) => it.id === row?.goal)?.name
                : listView?.selected_row?.name ?? "",
            id: row ? row?.goal : listView?.selected_row?.id ?? "",
          },
          value: "",
          list: listView?.list ?? [],

          listLabel: listView?.selected_row_field ?? "name",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "select",
          disabled: true,
          api: "goal",
        },
        // pathOrderInput(),
        weightageInput(),

        // logoInput(),
        descriptionInput(),
        internalRemarkInput(),
      ] as inputProp[];
    },
  },
  [allModulesInMockTest[5]]: {
    apiCall: false,
    isServerSide: true,
    // arrange_path: t
    filterValue: [
      { name: "name", operator: "startsWith", type: "string", value: "" },
    ],
    success: (row: ObjectValues | undefined, res: ObjectValues) => {
      return row
        ? `New Question has been successfully created. Once the corresponding Executive approves the Question, it will be available.`
        : `Question has been successfully updated. Once the corresponding Executive approves the changes in Question, it will be available.`;
    },
    isTab: true,
    formatList: (list: ObjectValues[]) => {
      return list && list.length > 0
        ? list.map((item: ObjectValues) => {
            return {
              ...item,
              name: item?.translation?.text ?? "",
            };
          })
        : [];
    },
    tableHeaders: (content: any) => {
      const margin = lodash.divide(Number(60), 7);
      const all = lodash.divide(Number(content.tableWidth), 7) - margin;

      const eqWidth = all;
      return [
        {
          field: "question",
          width: eqWidth,
          sortable: true,
          headerName: "Question",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.translation?.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return (
              <MessagePopper
                fetchSingleQuestionDetails={content.fetchSingleQuestionDetails}
                text={text ?? ""}
                type="ck"
                id={"question"}
                question={data}
              />
            );
          },
        },
        {
          field: "answer",
          width: eqWidth,
          sortable: true,
          headerName: "Answer",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.answer?.translation?.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return <MessagePopper text={text ?? ""} type="ck" id={"Answer"} />;
          },
        },
        {
          field: "explanation",
          width: eqWidth,
          sortable: true,
          headerName: "Explanation",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: ({ value, data }: any) => {
            const text =
              data?.explanation?.translation?.find(
                (item: { language: any }) =>
                  item.language === data?.default_language
              )?.text ?? "";

            return (
              <MessagePopper text={text ?? ""} type="ck" id={"Explanation"} />
            );
          },
        },
        {
          field: "default_language",
          width: eqWidth,
          sortable: true,
          headerName: "Default Language",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            return (
              <Box
                sx={{
                  textTransform: "capitalize",
                  height: "100%",
                  ...rowVerticalCenter,
                }}
              >
                {value ?? ""}
              </Box>
            );
          },
        },
        {
          field: "tag",
          width: eqWidth,
          sortable: true,
          headerName: "Question Tag",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",
          renderCell: ({ value }: any) => {
            const tags =
              content.tags_list.filter((it: any) => value.includes(it.id)) ??
              [];
            return (
              <MessagePopper
                content={
                  <Box>
                    {tags.map(({ name }: any) => (
                      <Chip
                        label={name}
                        sx={{
                          margin: "5px",
                          background: "#E3F4E7",
                          maxWidth: "calc(100% - 10px)",
                          "& .MuiChip-label": {
                            fontSize: "0.69srem",
                            lineHeight: "1rem",
                          },
                        }}
                      ></Chip>
                    ))}
                  </Box>
                }
                id={"question"}
              />
            );
          },
        },
        {
          field: "status",
          width: eqWidth,
          sortable: true,
          headerName: "Status",
          textAlign: "start",
          textVerticalAlign: "middle",
          headerAlign: "start",

          renderCell: (ss: any) => {
            return statusContent(ss, content, eqWidth);
          },
        },
        actionColumn(content, eqWidth),
      ];
    },
    inputSteps: () => {
      return [];
    },
    formatPayload: (data: ObjectValues, language?: any) => {
      const newObj: any = {
        //cluster: data.parent,
        text: data.Question,
        tag: data["Question Tags"]?.length > 0 ? data["Question Tags"]?.map((it: any) => it.id) : [],
        options: [
          {
            text: data["Option A"],
            is_correct: data.correct_answer?.label === "Option A" ? true : false,
            media_urls: "",
            order: 1
          },
          {
            text: data["Option B"],
            is_correct: data.correct_answer?.label === "Option B" ? true : false,
            media_urls: "",
            order: 2
          },
          {
            text: data["Option C"],
            is_correct: data.correct_answer?.label === "Option C" ? true : false,
            media_urls: "",
            order: 3
          },
          {
            text: data["Option D"],
            is_correct: data.correct_answer?.label === "Option D" ? true : false,
            media_urls: "",
            order: 4
          },
        ],
        explanation: {
          media_urls: "",
          text: data.Explanation,
        },
        //question: que_id ? que_id : null,
        language: language,
        // default_language:data.primary_language,
        //is_default_language: data.is_default_language ?? que_id ? false : true,
        media_urls: "",
      };
      return newObj;
    },
    inputArray: (content: any, listView: structureProps) => {
      const row: any = content.selected_row;
      return [
        ImageUpload(),
        // {
        //   label: "Default Language",
        //   placeholder: "Select Default Language",
        //   value: undefined,
        //   error: false,
        //   helperText: " c",
        //   endAdornment: "",
        //   not_required: true,
        //   fieldType: "checkbox",
        //   api: "is_default_language",
        // },

        // {
        //   label: "Question Language",
        //   placeholder: "Select Question Language",
        //   value: undefined,
        //   list: languages.map((it: string) => {
        //     return {
        //       label: convertFirstUpper(it),
        //       id: it,
        //       value: convertFirstUpper(it),
        //     };
        //   }),
        //   error: false,
        //   helperText: "",
        //   endAdornment: "",
        //   fieldType: "autocomplete",
        //   listLabel: "label",
        //   api: "primary_language",
        //   // freeSolo:true
        // },
        // {
        //   label: "Parent Cluster",
        //   pinned: true,
        //   defaultValue: {
        //     value:
        //       row && listView
        //         ? listView.list?.find((it) => it.cluster.id === row?.cluster)
        //             ?.cluster?.name
        //         : listView?.selected_row?.cluster?.name ??
        //           listView?.selected_row?.name ??
        //           "",
        //     id: row
        //       ? row?.cluster
        //       : listView?.selected_row?.cluster?.id ??
        //         listView?.selected_row?.id ??
        //         "",
        //   },
        //   value: "",
        //   list:
        //     listView?.list && listView?.list.length > 0
        //       ? listView?.list.map((item: ObjectValues) => {
        //           return {
        //             ...item,
        //             name: item?.cluster?.name ?? item?.name ?? "",
        //             id: item?.cluster?.id ?? item?.id ?? "",
        //           };
        //         })
        //       : [],

        //   listLabel: listView?.selected_row_field ?? "name",
        //   error: false,
        //   helperText: "",
        //   endAdornment: "",
        //   fieldType: "select",
        //   disabled: true,
        //   api: "parent",
        // },
        {
          label: "Question",
          // grid:{xs:12,sx:{width:"100%"}},
          fullWidth: true,
          // not_required: true,
          placeholder: "Enter Question",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "cktextEditor",
          api: "question",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option A",
          // not_required: true,
          placeholder: "Enter Option A",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "cktextEditor",
          dataType: "option",
          api: "option1",
        },
        {
          label: "Option B",
          // not_required: true,
          placeholder: "Enter Option B",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option2",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option C",
          // not_required: true,
          placeholder: "Enter Option C",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option3",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Option D",
          // not_required: true,
          placeholder: "Enter Option D",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          dataType: "option",
          fieldType: "cktextEditor",
          api: "option4",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Explanation",
          // grid:{xs:12,sx:{width:"100%"}},
          fullWidth: true,
          // not_required: true,
          placeholder: "Enter Explanation",

          value: "",
          error: false,
          helperText: "",
          endAdornment: "",
          fieldType: "cktextEditor",
          api: "explanation",

          // defaultValue: {
          //   // value: row?.cluster?.lod_index ?? "",
          // },
        },
        {
          label: "Question Tags",
          // pinned: true,
          // defaultValue: {
          //   // value:
          //   //   row && listView
          //   //     ? listView.list?.find((it) => it.id === row?.parent)?.name
          //   //     : listView?.selected_row?.name ?? "",
          //   // id: row ? row?.parent : listView?.selected_row?.id ?? "",
          // },
          // value: [],
          list: content.tags_list ?? [],
          multiple: true,
          listLabel: "name",
          error: false,
          helperText: " ",
          endAdornment: "",
          fieldType: "autocomplete",
          not_required: true,
          freeSolo: {
            dialog: true,
          },
          // disabled: true,
          api: "tag",
        },
      ] as inputProp[];
    },
  },
};

export const getNextModule = (currentModule: string) => {
  const currentIndex = allModules.indexOf(currentModule);
  const nextIndex = (currentIndex + 1) % allModules.length;
  return allModules[nextIndex];
};

export const getNextModuleInMockTest = (currentModule: string) => {
  const currentIndex = allModulesInMockTest.indexOf(currentModule);
  const nextIndex = (currentIndex + 1) % allModulesInMockTest.length;
  return allModulesInMockTest[nextIndex];
};

export interface structureProps {
  fetchAgain: boolean;
  parentDetails: ObjectValues | null;
  title: string; //(typeof allModules)[number] | (typeof allModulesInMockTest)[number];
  list: ObjectValues[];
  child: structureProps | null;
  create: boolean;
  edit: boolean;
  selected_row: ObjectValues | null;
  selected_row_field?: string;
  dockedList?: boolean;
  dockedTable?: boolean;
  selectedTab: statusType;
  selectedTabInClusterQuestion: statusType;
  selectedTabInMockTest: statusType;
  selectedTabInMockTest2: statusType;
  selectedTabInMockTest3: statusType;
  loading: boolean;
}

export interface structureMockProps {
  fetchAgain: boolean;
  parentDetails: ObjectValues | null;
  title: (typeof allModulesInMockTest)[number];
  list: ObjectValues[];
  child: structureProps | null;
  create: boolean;
  edit: boolean;
  selected_row: ObjectValues | null;
  selected_row_field?: string;
  dockedList?: boolean;
  dockedTable?: boolean;
  selectedTab: statusType;
  selectedTabInMockTest: statusType;
  selectedTabInMockTest2: statusType;
  selectedTabInMockTest3: statusType;
  loading: boolean;
}

export const defaultStructure: structureProps = {
  title: allModules[0],
  fetchAgain: true,
  parentDetails: null,
  list: [],
  child: null,
  create: false,
  edit: false,
  selected_row: null,
  dockedTable: true,
  selectedTab: allStatus[0],
  selectedTabInClusterQuestion: allStatusInClusterQuestion[0],
  selectedTabInMockTest: allStatusInMockTestModule[0],
  selectedTabInMockTest2: allStatusInMockTestModule2[0],
  selectedTabInMockTest3: allStatusInMock[0],
  dockedList: false,
  loading: true,
};
export const routes = {
  initial: "/",
  profile: "/profile",
  settings: "/settings",
  home: "/home",
  exams: "/exams",
  mockTest: "/mockTest",
  contentFeedback: "/contentFeedback",
  orderManagement: "/orderManagement",
};
export const SidebarContent = {
  heading: "Navigation Menu",
  closeheading: "Menu",
  list: [
    { label: "Home", icon: (sx: any) => home(sx), path: routes.home },
    { label: "Exams & Topical Mastery", icon: (sx: any) => book(sx), path: routes.exams },
    { label: "Mock Tests", icon: (sx: any) => mockTest(sx), path: routes.mockTest },
    { label: "Content Feedback", icon: contentFeedback, path: routes.contentFeedback },
    { label: "Order Management", icon: orderManagement, path: routes.orderManagement },
  ],
};
export const contents: any = {
  action: (action: string) => `Reason for ${action}`,
  actionText: (name: string, action: string) => {
    return `Enter your reason to ${action} the ${name}.`;
  },
  successStatusChange: (exam: string, status: string) => {
    return `${exam} status has been successfully updated to ${status} and moved to ${status} Tab for further action`;
  },
  feedback: "Enter Feedback",
  viewhistory: "View history",
  hidehistory: "Hide history",
  viewhistorySm: "View",
  hidehistorySm: "Hide",
};
export const headerContent = {
  list: [
    {
      label: "Edit Profile",
      icon: edit,
      path: routes.profile,
    },
    {
      label: "Settings",
      icon: settings,
      path: routes.settings,
    },
    {
      label: "Sign Out",
      icon: Signout,
      path: "",
    },
  ],
};
export interface moduleListProps {
  label: string;
  icon: any;
  path: string;
}
export const allRouteModules: moduleListProps[] = [
  ...SidebarContent.list,
  ...headerContent.list,
].filter((obj: any) => obj.path);
export const breadcrumbContent = (
  module: string,
  selectedValue?: ObjectValues | null
) => {
  return {
    list: `List of ${module?.charAt(0)?.toUpperCase() + module?.slice(1)}`,
    create: `Add New ${module}`,
    edit: `Edit ${module}`,
    selected: selectedValue ?? null,
  };
};
export const checkPermisions = (
  myPermissions: string[],
  action: "view" | "change" | "add" | "delete",
  title: string,
  source: string
) => {
  // syllabus.view_question
  return myPermissions.includes(`${source}.${action}_${title}`);
};
export const StopScroll = (e: {
  target: {
    addEventListener: (
      arg0: string,
      arg1: (e: any) => void,
      arg2: { passive: boolean }
    ) => void;
  };
}) => {
  e.target.addEventListener(
    "wheel",
    function (e: { preventDefault: () => void }) {
      e.preventDefault();
    },
    { passive: false }
  );
};
